var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-manage-layout","title":_vm.modalTitle,"no-close-on-backdrop":"","size":"lg"},on:{"ok":_vm.onOk,"close":_vm.onCloseEvent,"cancel":_vm.onCloseEvent}},[_c('validation-observer',{ref:"cmsLayoutForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Use image"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.isUseImageAsTitle),callback:function ($$v) {_vm.isUseImageAsTitle=$$v},expression:"isUseImageAsTitle"}},[_vm._v(" Use Image as title ")])],1),(!_vm.isUseImageAsTitle)?_c('validation-provider',{attrs:{"name":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.layoutForm.title),callback:function ($$v) {_vm.$set(_vm.layoutForm, "title", $$v)},expression:"layoutForm.title"}})],1)]}}],null,false,2605971535)}):_c('validation-provider',{attrs:{"name":"image_src","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.layoutForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.layoutForm.imageSrc = value || ''); }}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"title_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title Type"}},[_c('b-form-select',{attrs:{"id":"title_type","state":errors[0] ? false : null},model:{value:(_vm.layoutForm.titleType),callback:function ($$v) {_vm.$set(_vm.layoutForm, "titleType", $$v)},expression:"layoutForm.titleType"}},_vm._l((_vm.cmsEnumTypes.titles),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)]}}])}),_c('validation-provider',{attrs:{"name":"is_slider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Use slider (all card settings inside row will be ignore) recommnded use square image (1:1) in row "}},[_c('b-form-checkbox',{attrs:{"name":"is_slider","switch":"","state":errors[0] ? false : null},model:{value:(_vm.layoutForm.isSlider),callback:function ($$v) {_vm.$set(_vm.layoutForm, "isSlider", $$v)},expression:"layoutForm.isSlider"}})],1)]}}])}),(_vm.layoutForm.isSlider)?_c('validation-provider',{attrs:{"name":"slide_per_view","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Slide per views"}},[_c('b-form-input',{attrs:{"id":"slide_per_view","state":errors[0] ? false : null},model:{value:(_vm.layoutForm.slidesPerView),callback:function ($$v) {_vm.$set(_vm.layoutForm, "slidesPerView", $$v)},expression:"layoutForm.slidesPerView"}})],1)]}}],null,false,951297426)}):_vm._e(),_c('b-form-group',{attrs:{"label":"Background type"}},[_c('b-form-select',{model:{value:(_vm.layoutForm.backgroundType),callback:function ($$v) {_vm.$set(_vm.layoutForm, "backgroundType", $$v)},expression:"layoutForm.backgroundType"}},_vm._l((_vm.backgroundTypes),function(item){return _c('b-form-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),(_vm.layoutForm.backgroundType !== 'NO_BG')?_c('b-form-group',{attrs:{"label":"Background color"}},[_c('ColorPicker',{staticClass:"mb-2",staticStyle:{"width":"220px"},attrs:{"color":_vm.layoutForm.backgroundColor,"sucker-hide":true},on:{"changeColor":_vm.onChangeColor}}),_c('b-form-input',{model:{value:(_vm.layoutForm.backgroundColor),callback:function ($$v) {_vm.$set(_vm.layoutForm, "backgroundColor", $$v)},expression:"layoutForm.backgroundColor"}}),(_vm.layoutForm.backgroundType === 'BG_COLOR_BLUR')?_c('small',[_vm._v("When background type `BG_COLOR_BLUR` you should select `Alpha channel` less than 1")]):_vm._e()],1):_vm._e(),_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-input',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.layoutForm.position),callback:function ($$v) {_vm.$set(_vm.layoutForm, "position", $$v)},expression:"layoutForm.position"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }